import List from '@material-ui/core/List'
import Popover from '@material-ui/core/Popover'
import React, { FunctionComponent } from 'react'

import { IMenuItem } from '../../../hooks/useLayout'
import DrawerMenuItemList from '../drawer-menu-item-list'

// @ts-ignore
export interface PopoverMenuProps {
  menuItems?: IMenuItem[]
  children?: JSX.Element|React.ReactNode
}

export const PopoverMenu: FunctionComponent<PopoverMenuProps> = ({ menuItems = [], children }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <div aria-describedby={id} onClick={handleClick as any}>
        {children}
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <List component="div" disablePadding>
          {menuItems.map((item: IMenuItem, index: number) => (
            <DrawerMenuItemList {...item} key={index} />
          ))}
        </List>
      </Popover>
    </div>
  );
}

export default PopoverMenu
