import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export const CiscoLogo: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox={"0 0 240 130"} {...props}>
      <g id="Letters">
        <path
          id="O2"
          d="M206.6,106c0.1,14.2-21.7,14.2-21.6,0C184.9,91.8,206.7,91.8,206.6,106 M195.8,84.7
		c-28.7-0.1-28.7,42.9,0,42.8C224.5,127.5,224.5,84.5,195.8,84.7"
        />
        <path
          id="C2"
          d="M163,86.1c-42-11.8-41.3,51.9,0,39.8v-11.1c-26.6,12.5-26.4-30.2,0-17.6"
        />
        <path
          id="S1"
          d="M118.5,86c-8.5-3-26.3-1.5-25.5,11.6c0,13.6,15.4,9.7,17.5,16.9c0.9,5.8-15.9,3.3-17.4,2.3v9.5
		c7.8,2,29.1,2.7,28.3-12.7c0-5-3.1-9.7-9.8-11.8c-2.5-1.1-7.3-1.2-7.7-4.8c-0.8-5,13.7-2.9,14.6-2.1V86z"
        />
        <polyline id="I1" points="79,126.7 68.5,126.7 68.5,85.4 79,85.4 	" />
        <path
          id="path12"
          d="M54.2,86.1c-42-11.8-41.3,51.9,0,39.8v-11.1c-26.6,12.5-26.4-30.2,0-17.6"
        />
      </g>
      <g id="Bars">
        <path
          className="st0"
          d="M11.7,41c0.1-6.7-10.4-6.7-10.3,0v10.8c-0.1,6.7,10.4,6.7,10.3,0"
        />
        <path
          className="st0"
          d="M40.1,26.8c0.1-6.7-10.4-6.7-10.3,0v25.1c0,2.9,2.3,5.2,5.2,5.2s5.2-2.3,5.2-5.2V26.8H40.1z"
        />
        <path
          className="st0"
          d="M68.5,7.3c0.1-6.7-10.4-6.7-10.3,0v54.8c-0.1,6.7,10.4,6.7,10.3,0V7.3z"
        />
        <path
          className="st0"
          d="M86.6,26.8c-0.1-6.7,10.4-6.7,10.3,0v25.1c0,2.9-2.3,5.2-5.2,5.2c-2.9,0-5.2-2.3-5.2-5.2L86.6,26.8L86.6,26.8z
		"
        />
        <path
          className="st0"
          d="M115,41c-0.1-6.7,10.4-6.7,10.3,0v10.8c0.1,6.7-10.4,6.7-10.3,0"
        />
        <path
          className="st0"
          d="M153.7,26.8c0.1-6.7-10.4-6.7-10.3,0v25.1c0,2.9,2.3,5.2,5.2,5.2s5.2-2.3,5.2-5.2L153.7,26.8L153.7,26.8z"
        />
        <path
          className="st0"
          d="M182.2,7.3c0.1-6.7-10.4-6.7-10.3,0v54.8c-0.1,6.7,10.4,6.7,10.3,0V7.3z"
        />
        <path
          className="st0"
          d="M200.2,26.8c-0.1-6.7,10.4-6.7,10.3,0v25.1c0,2.9-2.3,5.2-5.2,5.2s-5.2-2.3-5.2-5.2L200.2,26.8L200.2,26.8z"
        />
        <path
          className="st0"
          d="M228.7,41c-0.1-6.7,10.4-6.7,10.3,0v10.8c0.1,6.7-10.4,6.7-10.3,0"
        />
      </g>
    </SvgIcon>
  );
};
