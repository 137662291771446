import CssBaseline from "@material-ui/core/CssBaseline";
import { useKeycloak } from "@react-keycloak/web"
import clsx from "clsx";
import React from "react";
import { isIE } from "react-device-detect";
import { useTranslation } from "react-i18next";

import useLayout from "../../hooks/useLayout";
import useNavigation from "../../hooks/useNavigation";
import Alerts from "../alerts/Alerts";
import TopBar from "./app-bar/AppBar";
import { Footer } from "./footer/Footer";
import { FooterContent } from "./footer/FooterContent";
import useStyles from "./Layout.styles";
import NavDrawer from "./nav-drawer/NavDrawer";

export const Layout = (props: {
  children: JSX.Element | React.ReactNode;
  title?: string;
}) => {
  const { keycloak, initialized } = typeof window !== 'undefined' // fix for gatsby ssr
    ? useKeycloak()
    : { keycloak: {} as Keycloak.KeycloakInstance, initialized: false };
  const { menus, signInButtonLabel, signOutButtonLabel } = useLayout();
  const classes = useStyles();
  const { handleDrawerClose, open, handleDrawerOpen } = useNavigation();
  const { t } = useTranslation();
  const defaultTitle = "Unified Cloud";

  if (!initialized) return null;

  if (!keycloak.authenticated) {
    keycloak.login();
    return null;
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <div className={classes.root}>
        <CssBaseline />
        <TopBar
          title={props.title || defaultTitle}
          {...{
            signInButtonLabel,
            signOutButtonLabel,
            handleDrawerOpen,
            handleDrawerClose,
            menus,
            open,
            keycloak,
          }}
        />
        <NavDrawer {...({ open, handleDrawerClose, menus } as any)} />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}
        >
          <div className={classes.drawerHeader} />
          {isIE ? (
            <Alerts
              title={t("landing.warning")}
              severity={"warning"}
              isOpen={true}
            />
          ) : null}
          <div style={{ marginTop: "1em" }}>{props.children}</div>
        </main>
      </div>
      <Footer drawerOpen={open}>
        <FooterContent />
      </Footer>
    </div>
  );
};

export default Layout;
