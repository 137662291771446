import AccountTreeIcon from "@material-ui/icons/AccountTree";
import DeveloperBoardIcon from "@material-ui/icons/DeveloperBoard";
import ErrorIcon from "@material-ui/icons/Error";
import HomeIcon from "@material-ui/icons/Home";
import LocalGroceryStoreIcon from "@material-ui/icons/LocalGroceryStore";
import NotificationsIcon from "@material-ui/icons/Notifications";
import PaymentIcon from "@material-ui/icons/Payment";
import PolicyIcon from "@material-ui/icons/Policy";
import RouterIcon from "@material-ui/icons/Router";
import StorageIcon from "@material-ui/icons/Storage";
import MonitoringIcon from "@material-ui/icons/Visibility";
import { useTranslation } from "react-i18next";

import useVersion from "./useVersion";

export interface IMenuItem {
  label: string;
  url?: string;
  isExternal?: boolean;
  icon?: any;
  menuItems: this[];
}

export default () => {
  useVersion();
  const { t } = useTranslation();

  const menu = [
    {
      icon: HomeIcon,
      label: "Home",
      url: "/",
    },
    {
      icon: PolicyIcon,
      label: "IAM & Admin",
      url: "/iam",
    },
    {
      icon: DeveloperBoardIcon,
      label: "Compute",
      menuItems: [
        {
          icon: DeveloperBoardIcon,
          label: "Overview",
          url: "/compute",
        },
        {
          icon: AccountTreeIcon,
          label: "Workloads",
          url: "/compute/virtualization",
        },
        {
          icon: StorageIcon,
          label: "Storage",
          url: "/compute/storage",
        },
        {
          icon: RouterIcon,
          label: "Networking",
          url: "/compute/networking",
        },
      ],
    },
    {
      icon: MonitoringIcon,
      label: "Monitoring",
      menuItems: [
        {
          icon: NotificationsIcon,
          label: "Alerts",
          url: "/monitoring",
        },
        {
          icon: ErrorIcon,
          label: "Alarms",
          url: "/monitoring/alarms",
        },
      ],
    },
    {
      icon: PaymentIcon,
      label: "Billing",
      url: "/billing",
    },
    {
      icon: LocalGroceryStoreIcon,
      label: "Marketplace",
      url: "/marketplace",
    },
  ];

  return {
    menus: menu as IMenuItem[],
    signInButtonLabel: t("Login"),
    signOutButtonLabel: t("auth.signOutButtonLabel"),
  };
};
