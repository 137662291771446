import clsx from "clsx";
import React from "react";
import useLayoutStyles from "../Layout.styles";

export interface FooterProps {
  children?: JSX.Element | React.ReactNode;
  drawerOpen: boolean | undefined;
}

export const Footer: React.FC<FooterProps> = ({ children, drawerOpen }) => {
  const classes = useLayoutStyles();

  return (
    <div
      className={clsx(classes.footer, {
        [classes.footerShift]: drawerOpen,
      })}
    >
      {children}
    </div>
  );
};
