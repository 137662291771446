import { Button, Dialog, DialogContent, makeStyles } from "@material-ui/core";
import React from "react";
import defaultUseFeedbackDialog from "../../hooks/useFeedbackDialog";
import FeedbackForm from "./FeedbackForm";

const useStyles = makeStyles(() => ({
  root: {
    color: "white",
  },
}));

const staticText = {
  buttonText: "Leave Feedback",
};

export interface FeedbackDialogProps {
  useFeedbackDialog: typeof defaultUseFeedbackDialog;
}

export const FeedbackDialog: React.FC<FeedbackDialogProps> = ({
  useFeedbackDialog = defaultUseFeedbackDialog,
}) => {
  const classes = useStyles();
  const {
    open,
    handleOpen,
    handleClose,
    handleFeedbackSubmitted,
    handleFeedbackCancelled,
  } = useFeedbackDialog();

  return (
    <>
      <Button color="primary" onClick={handleOpen} className={classes.root}>
        {staticText.buttonText}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="feedback-dialog"
      >
        <DialogContent>
          <FeedbackForm
            handleFeedbackCancelled={handleFeedbackCancelled}
            handleFeedbackSubmitted={handleFeedbackSubmitted}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
