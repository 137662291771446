import { Box, Grid } from "@material-ui/core";
import React from "react";
import { FeedbackDialog } from "../../../components/feedback/FeedbackDialog";
import useFeedbackDialog from "../../../hooks/useFeedbackDialog";

export const FooterContent: React.FC<{}> = () => {
  return (
    <Grid container justify="center">
      <Grid item>
        <Box pt={2} pb={2}>
          &copy; {new Date().getFullYear()} -
          <FeedbackDialog useFeedbackDialog={useFeedbackDialog} />
        </Box>
      </Grid>
    </Grid>
  );
};
